import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  TextBox,
  BannerLinks,
  Breadcrumbs,
  BlogSlider,
  Appointment
} from '../../components'

import BannerImage from '../../assets/internet-explorer-gravestone-close.jpeg'
import boxes from '../../components/BannerLinks/DefaultBox'
import cards from '../../components/BlogSlider/DefaultCards'

import { generateBreadcrumbsLinks } from '../../utils/index'

import Georg from '../../assets/georg_neu.png'

const InternetExplorerDeath = () => {
  const links = generateBreadcrumbsLinks(
    '/blog/internet-explorer-death',
    'Internet Explorer'
  )
  return (
    <Layout>
      <Helmet
        title="Tod des Internet Explorers"
        meta={[
          {
            name: 'description',
            content:
              'Der Internet Explorer hat abgedankt und sich von seinen Tagen als Webbrowser verabschiedet.'
          },
          {
            name: 'keywords',
            content:
              'holzweg, internet explorer, websites, IT'
          },
          {
            name: 'image',
            content:
              'https://www.holzweg.com/static/internet-explorer-gravestone-overlay-eecf97d10223033dcb2f49c8220e8353.png'
          },
          {
            property: 'og:description',
            content:
              'Der Internet Explorer hat abgedankt und sich von seinen Tagen als Webbrowser verabschiedet.'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/internet-explorer-gravestone-overlay-eecf97d10223033dcb2f49c8220e8353.png'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Tod der Ikone
          </span>
        }
        parallaxOFF={
          true
        }
      />
      <Breadcrumbs backLinkAlias=" " links={links} />
      <TextBox
        title={<span>Der Internet Explorer wird eingestellt</span>}
        text={
          <span>
            <p>
              <b>Der Internet Explorer hat abgedankt und sich von seinen Tagen als Webbrowser verabschiedet. </b>
              Und dafür hat er sich auch genug Zeit gelassen. Schon im Juni des letzen Jahres
              wurde der Support des Browsers eingestellt. Jetzt wurde verkündigt, dass er sich
              mit diesjährigem Valentinstag endgültig verabschieden wird. Als Software-Development
              Unternehmen bedeutet dieser Abschied für uns jedoch keine Trauer. Ganz im Gegenteil
              freuen wir uns darüber endlich um so einige Bugs erleichtert zu werden, die nur dank
              des Internet Explorers überhaupt entstanden waren.
            </p>
            <p>
              {' '}
                <a href="https://www.reuters.com/resizer/m7C0A10yAcdEFWbiVUs5ZvBFqTI=/960x0/filters:quality(80)/cloudfront-us-east-2.images.arcpublishing.com/reuters/2O2WVAXQQFK6DN5CTSOIT6RJXE.jpg" target="_blank">
                  <i>Bannerfoto: Jung Ki-Young via Reuters</i>
                </a>
              {' '}
            </p>
          </span>
        }
      />

      <Appointment image={Georg} />
      <BlogSlider
        cards={cards}
        singleRow={true}
        title="Weitere Blogbeiträge:"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default InternetExplorerDeath
